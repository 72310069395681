<template>
	<div class="wrapper">
        <el-menu :default-active="active" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">数据汇总</el-menu-item>
            <el-menu-item index="2">数据折线图</el-menu-item>
        </el-menu>
        <SummaryChild v-if="active == 1"/>
        <LineChart v-if="active == 2"/>
	</div>
</template>
<script>
    import LineChart from './component/lineChart'
    import SummaryChild from './component/summary'
	export default {
		name: "Summary",
		data() {
			return {
                active: '1'
			}
		},
		components: {
            LineChart,
            SummaryChild
        },
		methods: {
            handleSelect(i) {
                this.active = i
            }
			
		}
	};
</script>
<style lang="less" scoped>
.el-menu-item.is-active {
    background: content-box;
}
</style>