<template>
	<div class="wrapper" v-loading="loading">
        <div class="top-btn">
            <div>
                <el-button type="primary" size="small" @click="dialogVisible = true">选择数据</el-button>
                <el-date-picker size="small" style="width: 230px;margin-left:10px" @change="changeTime()"
                    v-model="time"
                    :clearable="false"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </div>
        </div>
		<div v-show="!no_data" id="main" class="echarts"></div>
        <div v-show="no_data" style="margin: 40px auto;color:red">暂无数据</div>
        <!-- 弹框 -->
        <el-dialog title="选择" :visible.sync="dialogVisible" width="800px" :before-close="close" :close-on-click-modal="false">
            <el-checkbox-group v-model="checkList" :max="5">
                <div v-for="(item,index) in list" :key="index">
                    <div class="list-title">{{ item.title }}</div>
                    <div class="list-child" v-for="(itemChild,indexChild) in item.listChild" :key="indexChild">
                        <div class="list-child-title">{{ itemChild.header }}：</div>
                        <div class="list-sun">
                            <el-checkbox class="list-sun-list" v-for="(itemSun,indexSun) in itemChild.listSun" :label="itemSun" :key="indexSun">{{ itemSun.name }}</el-checkbox>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
            <div style="text-align:right">
				<el-button type="primary" @click="checkListFun()">确定</el-button>
                <el-button @click="close()">取消</el-button>
            </div>
        </el-dialog>
	</div>
</template>
<script>
	import { lineChart } from '@/api/dataAnalysis.js';
	export default {
		name: "LineChart",
		data() {
			return {
                loading: false,
                dialogVisible: false,
                no_data: true,
                checkList: [], // 选中筛选项
                time: [], // 选中筛选时间
                list: [
                    {
                        title: '用户',
                        listChild: [
                            { 
                                header: '用户活跃', 
                                listSun: [{key:'user_every_day_pv',name:'每日pv'},{key:'user_every_day_uv',name:'每日uv'},{key:'user_register_uv',name:'每日注册用户uv'},{key:'user_phone_user_uv',name:'每日手机用户uv'}]
                            },
                            { 
                                header: '用户增长', 
                                listSun: [{key:'user_add_up_user',name:'累计授权注册用户数'},{key:'user_add_up_phone_user',name:'累计手机注册用户数'},{key:'user_new_add_user',name:'新增授权注册用户数'},{key:'user_new_add_phone_user',name:'新增手机注册用户数'}]
                            },
                            { 
                                header: '用户分享', 
                                listSun: [{key:'user_share_times',name:'分享次数'},{key:'user_share_open_times',name:'分享打开次数'},{key:'user_org_share_times',name:'机构分享次数'},{key:'user_org_share_open_times',name:'机构分享打开次数'}]
                            },
                        ]
                    },
                    {
                        title: '视频',
                        listChild: [
                            { 
                                header: '视频内容', 
                                listSun: [{key:'video_new_add_video',name:'每日新增视频'},{key:'video_add_up_video',name:'累计视频总数'},{key:'video_online_video',name:'上架视频总数'}]
                            },
                            { 
                                header: '视频播放', 
                                listSun: [{key:'video_play_times',name:'每日播放次数'},{key:'video_complete_play_times',name:'每日完播次数'},{key:'video_watch_uv',name:'每日观看人数'},{key:'video_real_play_times',name:'真实每日播放次数'},{key:'video_real_complete_play_times',name:'真实每日完播次数'},{key:'video_real_watch_uv',name:'真实每日观看人数'}]
                            },
                            { 
                                header: '视频互动', 
                                listSun: [{key:'video_vote_times',name:'每日点赞次数'},{key:'video_vote_uv',name:'每日点赞人数'},{key:'video_add_up_vote_times',name:'累计点赞次数'},{key:'video_real_vote_times',name:'真实点赞次数'},{key:'video_real_vote_uv',name:'真实点赞人数'},{key:'video_real_add_up_vote_times',name:'累计真实点赞次数'}]
                            },
                        ]
                    },
                    {
                        title: '课程',
                        listChild: [
                            { 
                                header: '试听课', 
                                listSun: [{key:'course_new_add_course',name:'每日新增课程'},{key:'course_add_up_course',name:'累计课程数量'},{key:'course_course_online_count',name:'上架课程总数'},{key:'course_new_add_coupon',name:'每日新增优惠券'},{key:'course_add_up_coupon',name:'累计优惠券数量'},{key:'course_coupon_online_count',name:'上架优惠券总数'}]
                            },
                        ]
                    },
                    {
                        title: '订单',
                        listChild: [
                            { 
                                header: '新订单', 
                                listSun: [{key:'order_new_add_order',name:'每日新增订单'},{key:'order_new_add_course_order',name:'每日新增课程订单'},{key:'order_new_add_coupon_order',name:'每日新增优惠券订单'},{key:'order_add_up_order',name:'累计总订单'},{key:'order_add_up_course_order',name:'累计总课程订单'},{key:'order_add_up_coupon_order',name:'累计总优惠券订单'}]
                            },
                            { 
                                header: '支付订单', 
                                listSun: [{key:'order_new_pay_order',name:'每日支付订单数'},{key:'order_add_up_pay_order',name:'累计支付订单数'},{key:'order_new_pay_course_order',name:'每日支付课程订单数'},{key:'order_add_up_pay_course_order',name:'累计支付课程订单数'},{key:'order_new_pay_coupon_order',name:'每日支付优惠券订单数'},{key:'order_add_up_pay_coupon_order',name:'累计支付优惠券订单数'}]
                            },
                            { 
                                header: '支付金额', 
                                listSun: [{key:'order_new_pay_amount',name:'每日支付订单金额'},{key:'order_new_pay_real_amount',name:'每日支付订单实付金额'},{key:'order_add_up_pay_amount',name:'累计收款金额'},{key:'order_pay_course_amount',name:'支付课程订单金额'},{key:'order_pay_course_real_amount',name:'支付课程订单实付金额'},{key:'order_pay_course_add_up_amount',name:'累计课程收款金额'},{key:'order_pay_coupon_amount',name:'支付优惠券订单金额'},{key:'order_pay_coupon_real_amount',name:'支付优惠券订单实付金额'},{key:'order_pay_coupon_add_up_amount',name:'累计优惠券收款金额'}]
                            },
                            { 
                                header: '核销', 
                                listSun: [{key:'order_day_write_off_order',name:'每日核销总订单'},{key:'order_day_write_off_course_order',name:'每日核销课程订单'},{key:'order_day_write_off_coupon_order',name:'每日核销优惠券订单'}]
                            },
                            { 
                                header: '拼团', 
                                // {key:'order_',name:'开团成功次数'},{key:'order_',name:'开团成功人数'},{key:'order_',name:'参团人次'},{key:'order_',name:'参团人数'},
                                listSun: [{key:'order_group_success',name:'拼团成功团数量'},{key:'order_add_open_group',name:'累计开团数量'},{key:'order_add_up_group_success',name:'累计成团数量'}]
                            },
                            { 
                                header: '退款', 
                                listSun: [{key:'order_new_add_refund`',name:'新增退款数量'},{key:'order_new_add_refund_amount',name:'退款金额'},{key:'order_add_up_refund',name:'累计退款数量'},{key:'order_add_up_refund_amount',name:'累计退款金额'}]
                            },
                        ]
                    },
                    {
                        title: '机构',
                        listChild: [
                            { 
                                header: '机构', 
                                listSun: [{key:'org_new_add_org_count',name:'新增机构'},{key:'org_add_up_org_count',name:'新增上架机构'},{key:'org_new_add_online_org',name:'累计机构数量'},{key:'org_add_up_online_org',name:'累计上架机构数量'},{key:'org_new_add_staff',name:'新增员工数量'},{key:'org_add_up_staff',name:'累计员工数量'}]
                            },
                        ]
                    }
                ]
			}
		},
		created() {
            this.setDefaultTime()
        },
		methods: {
            // 设置默认时间
            setDefaultTime() {
                let date = new Date();
                let time = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`
                let time2 = new Date(time).getTime() - 3600 * 1000 * 24 * 30
                if(this.time.length == 0) { // 默认选中最近一个月
                    this.time[0] = new Date(time2)
                    this.time[1] = new Date(time)
                }
            },
            // 选择数据筛选
            checkListFun() {
                if(this.checkList.length <= 0){
                    this.$message({
                        message: '请至少先选择一个筛选项',
                        type: 'warning'
                    })
                    return;
                }
                this.dialogVisible = false
                this.getData()
            },
            // 修改时间
            changeTime() {
                this.getData()
            },
            // 获取数据
            getData() {
                this.loading = true
                lineChart({
                    start_time: this.$common.setSearchTime(this.time)[0],
                    end_time: this.$common.setSearchTime(this.time)[1],
                    fields: this.checkList.length > 0 ? this.checkList.map(item=>item.key).join(',') : ''
                }).then((res) => {
                    if(res.data.no_data) {
                        this.no_data = true
                    }else {
                        this.no_data = false
                        this.setEchart(res.data)
                    }
                    this.loading = false
                }).catch((err) => {
                    console.log(err, 222)
                })
            },
			// 设置表格
			setEchart(data) {
                let checkListkey = this.checkList.map(item=>item.key) // 选中key
                let checkListName = this.checkList.map(item=>item.name) // 选中val
                // 设置数据
                let series = []
                checkListkey.forEach((item,i)=>{ 
                    series.push({
                        name: checkListName[i],
                        type: 'line',
                        data: data[item].map(item2=>Number(item2.data))
                    })
                })
				// 基于准备好的dom，初始化echarts实例
                var myChart = this.$echarts.init(document.getElementById('main'));
                // 指定图表的配置项和数据
                var option = {
                    title: {
                        text: '数据汇总'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: checkListName
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '5%',
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data[checkListkey[0]].map(item=>item.created_at)
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series
                };
                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);
			},
			close() {
                this.dialogVisible = false
            },
		}
	};
</script>
<style lang="less" scoped>
.echarts {
    width: 800px;
    height: 500px;
    margin: 15px auto
}
.list-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600
}
.list-child {
    display: flex;
    margin-bottom: 10px;
}
.list-child-title {
    width: 100px;
    margin-right: 10px;
    font-size: 14px;
    text-align: right;
}
.list-sun {
    flex: 1
}
.list-sun-list {
    width: 180px
}
</style>
