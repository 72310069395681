<template>
    <div>
        <div class="top-btn">
            <div></div>
            <el-date-picker size="small" @change="timeSearch()"
                v-model="time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
        </div>
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="(tab,tabI) in tabs" :key="tabI" :label="tab.title" :name="tab.lable" v-loading="loading">
                 <div class="flex">
                    <el-card class="card" shadow="hover" v-for="(item,index) in tab.titleList" :key="index">
                        <div class="zh">{{ item.zh }}</div>
                        <div class="en">{{ tab.data[item.en] || '--' }}</div>
                    </el-card>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import { dataSummaryUser, dataSummaryVideo, dataSummaryCourse, dataSummaryOrder, dataSummaryOrg } from '@/api/dataAnalysis.js';
export default {
    name: "SummaryChild",
    data() {
        return {
            loading: true,
            activeName: 'user', // 当前选中tab
            time: [], // 时间筛选
            tabs: [
                {
                    title: '用户', lable: 'user', update: true,
                    titleList: [
                        { zh: '总pv', en: 'total_pv' },
                        { zh: '总uv', en: 'total_uv' },
                        { zh: '注册用户访问', en: 'register_uv' },
                        { zh: '平均访问深度', en: 'access_depth' },
                        { zh: '累计注册用户', en: 'add_up_user' },
                        { zh: '新增注册用户', en: 'new_add_user' },
                        { zh: '累计非机构用户', en: 'add_up_not_org_user' },
                        { zh: '新增非机构用户', en: 'new_add_not_org_user' },
                        { zh: '分享次数', en: 'share_times' },
                        { zh: '分享人数', en: 'share_user_count' },
                        { zh: '分享打开次数', en: 'share_open_times' },
                        { zh: '分享打开人数', en: 'share_open_uv' },
                    ],  
                    data: {},
                },{
                    title: '视频', lable: 'video', update: true,
                    titleList: [
                        { zh: '总播放次数', en: 'total_play_count' },
                        { zh: '完播次数', en: 'total_complete_play_count' },
                        { zh: '完播率', en: 'total_complete_rate' },
                        { zh: '观看人数', en: 'total_watch_uv' },
                        { zh: '真实播放', en: 'real_play_count' },
                        { zh: '真实完播', en: 'real_complete_play_count' },
                        { zh: '真实完播率', en: 'real_complete_rate' },
                        { zh: '真实观看人数', en: 'real_watch_uv' },
                        { zh: '新增点赞', en: 'total_new_add_vote_count' },
                        { zh: '累计点赞', en: 'total_add_up_vote_count' },
                        { zh: '新增点赞人数', en: 'total_new_add_vote_uv' },
                        { zh: '累计点赞人数', en: 'total_add_up_vote_uv' },
                        { zh: '新增真实点赞', en: 'real_new_add_vote_count' },
                        { zh: '累计真实点赞', en: 'real_add_up_vote_count' },
                        { zh: '新增真实点赞人数', en: 'real_new_add_vote_uv' },
                        { zh: '累计真实点赞人数', en: 'real_add_up_vote_uv' },
                        { zh: '视频分享次数', en: 'video_share_times_count' },
                        { zh: '视频分享人数', en: 'video_share_uv' },
                        { zh: '分享视频打开次数', en: 'video_share_open_times' },
                        { zh: '分享视频打开人数', en: 'video_share_open_uv' },
                    ],  
                    data: {},
                },{
                    title: '课程', lable: 'course', update: true,
                    titleList: [
                        { zh: '课程总访问次数', en: 'course_total_pv' },
                        { zh: '课程卡片弹出次数', en: 'course_pop_times' },
                        { zh: '课程访问人数', en: 'course_total_uv' },
                        { zh: '新增课程数量', en: 'new_add_course' },
                        { zh: '累计课程数量', en: 'add_up_course' },
                        { zh: '当前上架课程', en: 'course_online_count' },
                        { zh: '全部下架课程', en: 'course_offline_count' },
                        { zh: '新增优惠券数量', en: 'new_add_coupon' },
                        { zh: '累计优惠券数量', en: 'add_up_coupon' },
                        { zh: '当前上架优惠券', en: 'coupon_online_count' },
                        { zh: '当前下架优惠券', en: 'coupon_offline_count' },
                        { zh: '新增开团', en: 'new_add_group' },
                        { zh: '累计开团', en: 'add_up_group' },
                        { zh: '成团数量', en: 'success_group_count' },
                    ],  
                    data: {},
                },{
                    title: '订单', lable: 'order', update: true,
                    titleList: [
                        { zh: '下单总数', en: 'order_count' },
                        { zh: '支付总数', en: 'order_pay_count' },
                        { zh: '订单总金额', en: 'order_total_amount' },
                        { zh: '实收金额', en: 'order_pay_amount' },
                        { zh: '核销总数', en: 'write_off_count' },
                        { zh: '课程下单总数', en: 'course_order_count' },
                        { zh: '课程支付总数（含拼团订单）', en: 'course_pay_count' },
                        { zh: '课程订单总金额', en: 'course_total_amount' },
                        { zh: '课程实收金额', en: 'course_pay_amount' },
                        { zh: '课程核销数量', en: 'course_write_off' },
                        { zh: '优惠券下单总数', en: 'coupon_order_count' },
                        { zh: '优惠券支付总数（含拼团订单）', en: 'coupon_pay_count' },
                        { zh: '优惠券订单总金额', en: 'coupon_total_amount' },
                        { zh: '优惠券实收金额', en: 'coupon_pay_amount' },
                        { zh: '优惠券核销数量', en: 'coupon_write_off' },
                    ],  
                    data: {},
                },{
                    title: '机构', lable: 'org', update: true,
                    titleList: [
                        { zh: '新增机构', en: 'new_add_org_count' },
                        { zh: '累计入驻机构', en: 'add_up_org_count' },
                        { zh: '全部上架机构', en: 'all_online_org' },
                        { zh: '全部下架机构', en: 'all_offline_org' },
                        { zh: '新增员工数量（含机构管理员）', en: 'new_add_staff' },
                        { zh: '累计员工数量', en: 'add_up_staff' },
                    ],  
                    data: {},
                },
            ],
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // tab切换
        handleClick() {
            this.getList()
        },
        // 获取数据
        getList() {
            switch (this.activeName) {
                case 'user':
                    this.getData(0, dataSummaryUser)
                    break;
                case 'video':
                    this.getData(1, dataSummaryVideo)
                    break;
                case 'course':
                    this.getData(2, dataSummaryCourse)
                    break;
                case 'order':
                    this.getData(3, dataSummaryOrder)
                    break;
                case 'org':
                    this.getData(4, dataSummaryOrg)
                    break;
                default:
                    this.getData(0, dataSummaryUser) 
            }
        },
        // 时间筛选
        timeSearch() {
            this.tabs.forEach(item => { // 几个tab都设置成数据更新状态
                item.update = true
            })
            this.getList()
        },
        // 获取数据信息
        getData(index, port) {
            if(!this.tabs[index].update) return; // 如果不用更新不再调用接口，避免多次请求
            this.loading = true
            port({
                start_time: this.$common.setSearchTime(this.time)[0],
                end_time: this.$common.setSearchTime(this.time)[1]
            }).then((res) => {
                this.tabs[index].data = res.data || {}
                this.loading = false
                this.tabs[index].update = false // 下次再切换的时候不用更新
            }).catch((err) => {
                console.log(err, 222)
            })
        },
    }
};
</script>
<style lang="less" scoped>
.el-tab-pane {
    min-height: 400px;
}
.flex {
    display: flex;
    flex-wrap: wrap;
}
.card {
    width: 240px;
    margin: 10px 10px 0 0;
    text-align: center;
}
.zh {
    font-size: 14px;
    color: #999;
}
.en {
    margin-top: 22px;
    font-size: 20px;
}
</style>